import React, {useRef, useState, createContext, useContext, useEffect} from 'react';
import {fetchMap} from '@deck.gl/carto';
import {GoogleMapsOverlay} from '@deck.gl/google-maps';
import {update as updateTween} from '@tweenjs/tween.js';

import {loadScript} from './utils';
import flyTo from './flyTo';
import orbit from './orbit';

import {getTripsLayer} from '/layers/trips';
import slides from './slides';

const GOOGLE_MAPS_API_KEY = 'AIzaSyAD1U8vfdDqlExJhbVK22ND_oln_WgGNyQ';
const GOOGLE_MAP_ID = '84591267f7b3a201';
const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=beta&map_ids=${GOOGLE_MAP_ID}`;

const initAppState = {
  currentSlide: null,
  viewState: {
    longitude: -0.15,
    latitude: 51.5,
    zoom: 11,
    heading: 270,
    tilt: 50
  }
};

export const AppStateContext = createContext(initAppState);

const DRIVERS_LABEL = 'Driver locations';
function getDriversLayer(layers) {
  return layers.filter(l => l.props.cartoLabel === DRIVERS_LABEL)[0];
}

let map, overlay, tween;

export const AppStateStore = ({children}) => {
  const [currentSlide, setCurrentSlide] = useState(initAppState.currentSlide);
  const [layers, setLayers] = useState([]);
  const [viewState, setViewState] = useState(initAppState.viewState);

  const [animationFlag, setAnimationFlag] = React.useState();
  const forceUpdate = React.useCallback(() => setAnimationFlag({}), []);

  // Animation using the TripsLayer
  const maxTime = 50000;
  let currentTime = useRef(0);
  let requestRefLayer = useRef(null);
  let requestRefCamera = useRef(null);
  let cameraTransitionCompleted = true;

  const updateViewState = function (viewState, shouldOrbit) {
    setViewState({
      ...viewState,
    });
    if (tween) {
      tween.stop();
    }
    tween = flyTo(
      map, 
      viewState,
      () => {
        if (!shouldOrbit) {
          cameraTransitionCompleted = true
        }
      }
    );
    if (shouldOrbit) {
      tween.chain(orbit(map, viewState));
    }
    tween.start();
  };

  useEffect(async () => {
    setCurrentSlide(0);

    const [_] = await Promise.all([loadScript(GOOGLE_MAPS_API_URL)]);

    map = new google.maps.Map(document.getElementById('map'), {
      center: {
        lat: viewState.latitude, 
        lng: viewState.longitude
      },
      heading: viewState.heading,
      tilt: viewState.tilt,
      zoom: viewState.zoom,
      keyboardShortcuts: false,
      clickableIcons: false,
      disableDefaultUI: true,
      mapId: GOOGLE_MAP_ID
    });

    map.addListener('mousedown', (evt) => {
      if (tween) {
        tween.stop();
        tween = null;
      }
    });

    overlay = new GoogleMapsOverlay();
    overlay.setMap(map);
  }, []);

  useEffect(() => {
    if (currentSlide !== null) {
      const {
        mapID: cartoMapId,
        layers,
        view,
        orbit: shouldOrbit,
        parameters,
        props,
        embed,
        image
      } = slides[currentSlide];
      if (embed === undefined && image === undefined) {
        if (cartoMapId) {
          fetchMap({cartoMapId}).then(
            ({initialViewState: mapViewState, mapStyle, layers: mapLayers}) => {
              let _mapLayers = mapLayers.map((l) =>
                l.clone({
                  parameters: {...l.props.parameters, ...parameters},
                  ...props
                })
              );

              if (currentSlide <= 1) {
                // For the first two slides (using the same map), we need to transform the
                // Driver Locations layer from CartoLayer to TripsLayer
                const driversLayer = getDriversLayer(_mapLayers);
                if (!driversLayer) {
                  console.error(`Cannot find drivers layer. Check the map has a layer with label: "${DRIVERS_LABEL}"`);
                }
                _mapLayers = _mapLayers.map(l => {
                  return l === driversLayer ? getTripsLayer(l) : l
                });
              }

              setLayers(_mapLayers);
              overlay.setProps({ layers: _mapLayers });

              if (currentSlide != 0) {
                // For the first slide, we don't load the map viewstate
                // We need to transform deck.gl viewState parameters to the
                // parameters defining the view in Google Maps
                const gmapView = {
                  lng: mapViewState.longitude,
                  lat: mapViewState.latitude,
                  zoom: mapViewState.zoom + 1,
                  heading: mapViewState.bearing,
                  tilt: mapViewState.pitch
                };
                updateViewState(gmapView, shouldOrbit);
              }
              if (currentSlide < 1) {
                // Trigger the animation manually by re-rendering the component so the layer animation useEffect hook is executed
                forceUpdate();
              }
            }
          );
        } else {
          const _mapLayers = layers.map((l) => l.clone());
          setLayers(_mapLayers);
          overlay.setProps({ layers: _mapLayers });
          if (view && view.longitude !== undefined) {
            updateViewState(view, shouldOrbit);
          }
        }
      }
    }
  }, [currentSlide]);

  // Layer Animation (Trips Layer)
  const animateLayer = () => {
    if (layers.length > 0 && currentSlide <= 1) {
      currentTime.current = (currentTime.current + 10) % maxTime;
      const _mapLayers = layers.map(l => l.clone({currentTime: currentTime.current}));
      setLayers(_mapLayers);
      overlay.setProps({ layers: _mapLayers });
      requestRefLayer.current = requestAnimationFrame(animateLayer);
    }
  }

  React.useEffect(() => {
    requestRefLayer.current = requestAnimationFrame(animateLayer);
    return () => cancelAnimationFrame(requestRefLayer.current);
  }, [currentSlide, animationFlag]); 

  // Camera Animation
  const animateCamera = () => {
    if (!cameraTransitionCompleted) {
      updateTween();
      requestRefCamera.current = requestAnimationFrame(animateCamera);
    }
  }

  React.useEffect(() => {
    cameraTransitionCompleted = false;
    requestRefCamera.current = requestAnimationFrame(animateCamera);
    return () => {
      cancelAnimationFrame(requestRefCamera.current);
    }
  }, [currentSlide]); 

  return (
    <AppStateContext.Provider
      value={{
        next: () => {
          if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
          }
        },
        prev: () => {
          if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
          }
        },
        reset: () => {
          setCurrentSlide(0);
        },
        currentSlide,
        layers,
        slidesNumber: slides.length,
        viewState
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const AppStateContextConsumer = AppStateContext.Consumer;

export function useAppState() {
  return useContext(AppStateContext);
}
